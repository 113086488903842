




















































import { Component, Vue } from 'vue-property-decorator';
import data from '../data/data';

@Component({
  components: {},
})
export default class Product extends Vue {
  product = data.products
    .map((product) => {
      return { ...product, src: require(`../assets/images/${product.src}`) };
    })
    .find((p): boolean => p.id === this.$route.params.id);
}
