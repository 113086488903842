





















































































































import { Component, Vue } from 'vue-property-decorator';
import data from '../data/data';

@Component({
  components: {},
})
export default class Products extends Vue {
  products = data.products.map((product) => {
    return { ...product, src: require(`../assets/images/${product.src}`) };
  });
  featuredProducts: {
    src: string;
    id: string;
    name: string;
    description: string;
  }[] = [];

  created(): void {
    this.featuredProducts = this.products.slice(0, 2);
    this.products = this.products.slice(2, 5);
  }
}
